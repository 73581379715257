<template>
  <div class="container">
    <div class="pa-20">
      <div class="flex f-center f-between">
        <img class="logo" src="~@/assets/img/logo.png" />
        <div class="flex f-center back-box" @click="$router.replace('/')">
          <img class="back" src="~@/assets/img/back.png" />
          <span>返回</span>
        </div>
      </div>
      <div class="flex f-between ma-t-10">
        <div class="box">
          <div class="title">
            <span class="font-bold">预约信息（个人预约）</span>
            <span class="more" @click="show = true">查看更多》</span>
          </div>
          <div class="content">
            <div class="flex f-end width50">
              <div class="width60">
                <div>公司：{{ info.company }}</div>
                <div class="ma-t-10">姓名：{{ info.name }}</div>
                <div class="ma-t-10">
                  来访类型：{{ $util.getVisitType(info.visitType) }}
                </div>
                <div class="ma-t-10">车牌号：{{ info.plateNo }}</div>
              </div>
            </div>
            <div class="flex f-end width50">
              <div class="width60">
                <div class="t-omit-1" @click="show = true">来访理由：{{ info.reason }}</div>
                <div class="ma-tb-10">受访人：{{ info.intvName }}</div>
                <div class="ma-tb-10">
                  受访人座机/手机：{{ info.intvPhone }}
                </div>
                <div>访问时间：{{ info.visitDate }} {{info.visitEndDate? '~ '+info.visitEndDate : '' }}</div>
                <div class="ma-t-10"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="box box1">
          <div class="title">
            <span class="font-bold">防疫信息</span>
          </div>
          <div class="content">
            <div class="width100 pa-lr-10">
              <div class="flex f-between">
                <div class="width60">目前身体状况：{{ physicalCondition(info.epidInfo.physicalCondition) }}</div>
                <div class="width40">随申码颜色：{{shanghaiCode(info.epidInfo.shanghaiCode)}}</div>
              </div>
              <div class="flex f-between ma-tb-10">
                <div class="width60">7天内是否有高风险地区旅居史：{{info.epidInfo.highRiskFlag == 0 ? '否' : '是'}}</div>
                <div class="width40">是否完成抵沪落地检：{{info.epidInfo.landingInspection == 0 ? '否' : '是'}}</div>
              </div>
              <div>是否有本市72小时内有效核酸报告或48小时内外地有效核酸报告：{{info.epidInfo.nucleicAcidReport == 0 ? '否' : '是'}}</div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="box4">
        <div class="title t-center">确认信息</div>
        <div class="form pa-tb-20">
          <!-- <div class="flex f-center f-between ma-b-10">
            <div class="width30 t-right"><span class="star">*</span>体温</div>
            <el-select v-model="temperature" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div> -->
          <div class="flex f-center f-between ma-tb-10">
            <div class="width30 t-right">
              <span class="star">*</span>访客证号码
            </div>
            <div v-if="isOlnyShowCardNo" style="width:65%">
              {{ cardNo }}
            </div>
            <van-field class="input" v-model="cardNo" label="" v-else/>
          </div>
          <div class="flex f-center f-between ma-tb-10">
            <div class="width30 t-right">访客停车证号码</div>
            <van-field class="input" v-model="parkingNoList" label="" />
          </div>
          <div class="flex f-end ma-b-30">
            <van-checkbox v-model="intvConfirm" shape="square"
              >受访人已经同意入内</van-checkbox
            >
          </div>
          <div class="width60 ma-lr-auto">
            <van-button
              type="primary"
              size="normal"
              class="width100"
              color="#003AA9"
              @click="submit"
              >确认</van-button
            >
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="show">
      <div class="overlays">
        <div class="overlay-box ma-b-10">
          <div>公司：{{ info.company }}</div>
          <div class="ma-tb-10">姓名：{{ info.name }}</div>
          <div class="ma-tb-10">手机号：{{ info.phone }}</div>
          <div class="ma-tb-10">受访人：{{ info.intvName }}</div>
          <div class="ma-tb-10">受访人部门：{{ info.intvDepartment }}</div>
          <div class="ma-tb-10">受访人座机/手机：{{ info.intvPhone }}</div>
          <div class="ma-tb-10">车牌号：{{ info.plateNo }}</div>
          <div class="ma-tb-10">访问时间：{{ info.visitDate }}  {{info.visitEndDate? '~ '+info.visitEndDate : '' }}</div>
          <div class="ma-tb-10">
            来访类型：{{ $util.getVisitType(info.visitType) }}
          </div>
          <div>来访理由：{{ info.reason }}</div>
        </div>
        <van-icon color="#fff" @click="show = false" name="close" size="30" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    return {
      show: false,
      info: {
        epidInfo: {},
      },
      options: [
        {
          value: "0",
          label: "正常",
        },
        {
          value: "1",
          label: "不正常",
        },
      ],
      code: 0,
      intvConfirm: false, //受访人意见
      temperature: "0", // 体温
      parkingNoList: "", // 停车证号码
      cardNo: "", //访客证号码
      isOlnyShowCardNo:false
    };
  },
  created() {
    _this = this;
    _this.code = _this.$route.query.code;
    _this.loadData();
  },
  watch: {
    $route(to, from) {
      //  window.location.reload(); //监测到路由发生跳转时刷新一次页面
    },
  },
  //监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    //next方法传true或者不传为默认历史返回，传false为不执行历史回退
    next();
  },
  methods: {
    // 目前身体状况
    physicalCondition(val) {
      switch (val) {
        case 0:
          return "无不适";
        case 1:
          return "有发烧、咳嗽、流涕等不适症状";
      }
    },
    // 随申码
    shanghaiCode(val) {
      switch (val) {
        case 0:
          return "绿色";
        case 1:
          return "黄色";
        case 2:
          return "红色";
      }
    },
    // 来访类型
    visitType(val) {
      switch (val) {
        case 0:
          return "普通访问";
        case 1:
          return "提货";
        case 2:
          return "送货";
      }
    },

    // 加载数据
    loadData() {
      _this
        .$request({
          url: "/security/query",
          method: "GET",
          params: {
            code: _this.code,
            queryType: 0,
          },
        })
        .then((res) => {
          //如果访客证未归还，则回显目前的访客证
          if(res.cardReturned==0 && res.cardNo){
            _this.isOlnyShowCardNo=true
            _this.cardNo=res.cardNo
          }
          _this.info = res;
        });
    },

    // 确认
    submit() {
      if (_this.$validator.isEmpty(_this.$store.state.entranceId)) {
        return _this.$dialog
          .alert({
            title: "提示",
            message: "请先绑定通道",
          })
          .then(() => {
            _this.$router.push("/setting");
          });
      }
      if (_this.$validator.isEmpty(_this.temperature)) {
        return _this.$toast("请选择体温");
      }
      if (_this.$validator.isEmpty(_this.cardNo)) {
        return _this.$toast("请输入访客证号码");
      }
      if (!_this.intvConfirm) {
        return _this.$toast("请勾选受访人是否同意入内");
      }

      let form = {
        id: _this.info.id,
        intvConfirm: _this.intvConfirm ? 1 : 0,
        entranceId: _this.$store.state.entranceId,
        members: [
          {
            id: _this.info.id,
            cardNo: _this.cardNo,
            temperature: _this.temperature,
          },
        ],
        parkingNoList: _this.parkingNoList?[_this.parkingNoList]:[],
      };

      _this
        .$request({
          url: "/security/in",
          method: "POST",
          data: form,
        })
        .then(() => {
          _this.$router.replace("/enter/success");
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/style.less";

.overlays {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-height: 100vh;
  overflow-y: auto;
  .overlay-box {
    padding: 20px 40px;
    border-radius: 5px;
    background: #ffffff;
    text-align: left;
    font-size: 20px;
  }
}
</style>